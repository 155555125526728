import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card, CardBody, Input, Label, Modal, Button , UncontrolledTooltip} from "reactstrap";
import MetaTags from "react-meta-tags";
import Pagination from "components/Common/Pagination";
import useFullPageLoader from "../../components/Common/useFullPageLoader";
import { Link } from "react-router-dom";
import Select from "react-select";
import useMeeting from "./useMeeting";
import useCall from "./useCall";
import useReminder from "./useReminder";
import { get, post } from "../../helpers/api_helper";
import { GET_LEADS_DATA, GET_ALL_MASTERS, GET_EMPLOYEES_PERMISSION,LEAD_IMPORT, GET_REMINDER } from "../../helpers/api_url_helper";
import toastr from "toastr"
import { CSVLink } from "react-csv";
import AddMeeting from "./add-common-meeting";
import AddCall from "./add-common-call";
import AddReminder from "./add-reminder";
import { formatUpcomingEvent, getUserId } from "constants/CommonFunction";
import { downloadLeadSampleCsv } from "../../constants/CommonFunction"
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios"

const Lead = () => {
  const [tableData, settableData] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const { isShowing, toggle } = useMeeting();
  const { isShowingCall, toggleCall } = useCall();
  const { isShowingReminder, toggleReminder } = useReminder();
  const [leadId, setLeadId] = useState(null);
  const [Masterloaded, setMasterloaded] = useState(false);
  const [LeadSource, setLeadSource] = useState([]);
  const [Brand, setBrand] = useState([]);
  const [BusinessCategory, setBusinessCategory] = useState([]);
  const [AssignedTo, setAssignedTo] = useState([]);
  const [ClientLocation, setClientLocation] = useState([]);
  const [LeadsStages, setLeadsStages] = useState([]);
  const [filterStages, setFilterStages] = useState([])
  const [filterStartDate, setFilterStartDate] = useState([])
  const [filterEndDate, setFilterEndDate] = useState([])
  const [filterSource, setFilterSource] = useState([])
  const [filterBrand, setFilterBrand] = useState([])
  const [filterClientLocation, setFilterClientLocation] = useState([])
  const [filterOfficeLocation, setFilterOfficeLocation] = useState([])
  const [filterAssignedTo, setFilterAssignedTo] = useState([])
  const [filterBusinessCategory, setFilterBusinessCategory] = useState([])
  const [BoardReload, setBoardReload] = useState(false)
  const [reminderData, setReminderData] = useState(null);
  // csv usestate -----------
  const [newData, setNewData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [csv_load, setCsvLoad] = useState(false)
  const [sampleNewData, setSampleNewData] = useState([]);
  const [syncLoad, setSyncLoad] = useState(false)
  const csvInstance = useRef();
  const base_sync_url = process.env.REACT_APP_FACEBOOK_SYNC_URL
  const base_sync_redirect_url = process.env.REACT_APP_FACEBOOK_SYNC_REDIRECT_URL
  const loggedInUserId = getUserId()
  const syncFacebook = async () => {
    try {
      // Set loading state to true
      setSyncLoad(true);
      
      // Make the API call to check status first
      const statusResponse = await axios.get(`${base_sync_url}FacebookLeads/integrate_apis?id=${loggedInUserId}`);
      // Proceed only if status is 0
      if (statusResponse?.data?.code === 0) {
        // Make the API call to sync with Facebook
        const response = await axios.get(`${base_sync_url}FacebookLeads/login?redirect_url=${base_sync_redirect_url}`);
        // Check if a URL is present in the response and open it
        if (response?.data) {
          window.open(response.data, '_blank');
        } else {
          toastr.error("Something Went Wrong!");
        }
      } else if(statusResponse?.data?.Code === 1) {
        toastr.success(statusResponse?.data?.msg);
      }else{
        toastr.error("Something went wrong!")
      }
    } catch (error) {
      console.error('Error during API call:', error);
      toastr.error(error)
    } finally {
      // Set loading state to false after API call is completed
      fetchLeads();
      setTimeout(() => {
        setSyncLoad(false);
      }, 1000);
    }
};


  useEffect(() => {
    if (Masterloaded == false) {
      setMasterloaded(true)
      get(GET_ALL_MASTERS, { params: { masters: 'stage_master' } }).then(res => {
        if (res.status) {
          let data = res.data;
          setLeadsStages(data.stage_master)
        }
      }).catch(err => {
        toastr.error(err)
      })
    }
  })

  useEffect(() => {
    if (Masterloaded == false) {
      setMasterloaded(true)
      get(GET_ALL_MASTERS, { params: { masters: 'lead_source_master' } }).then(res => {
        if (res.status) {
          let data = res.data;
          setLeadSource(data.lead_source_master)
        }
      }).catch(err => {
        toastr.error(err)
      })
    }
  })
  
  useEffect(() => {
    if (Masterloaded == false) {
      setMasterloaded(true)
      get(GET_ALL_MASTERS, { params: { masters: 'brand_master' } }).then(res => {
        if (res.status) {
          let data = res.data;
          setBrand(data.brand_master)
        }
      }).catch(err => {
        toastr.error(err)
      })
    }
  })
  
  useEffect(() => {
    if (Masterloaded == false) {
      setMasterloaded(true)
      get(GET_ALL_MASTERS, { params: { masters: 'business_category_master' } }).then(res => {
        if (res.status) {
          let data = res.data;
          setBusinessCategory(data.business_category_master)
        }
      }).catch(err => {
        toastr.error(err)
      })
    }
  })
  
  useEffect(() => {
    if (Masterloaded == false) {
      setMasterloaded(true)
      get(GET_EMPLOYEES_PERMISSION, {status: 1 }).then(res => {
        if (res.status) {
          let data = res.data;
          setAssignedTo(data)
        }
      }).catch(err => {
        toastr.error(err)
      })
    }
    
  })
  
  useEffect(() => {
    if (Masterloaded == false) {
      setMasterloaded(true)
      get(GET_ALL_MASTERS, { params: { masters: 'location' } }).then(res => {
        if (res.status) {
          let data = res.data;
          setClientLocation(data.location)
        }
      }).catch(err => {
        toastr.error(err)
      })
    }
  })

  let PageSize = 10;

  useEffect(() => {
    fetchLeads()
  }, [currentPage, search, filterStages, filterStartDate,filterEndDate, filterSource, filterBrand, filterClientLocation,filterOfficeLocation,  filterAssignedTo, filterBusinessCategory, BoardReload]);  // pass `value` as a dependency 
  
  const fetchLeads = () =>{
    showLoader()
    let filter = {
      stage: filterStages,
      start_date: filterStartDate,
      end_date: filterEndDate,
      source: filterSource,
      brand: filterBrand,
      client_location: filterClientLocation,
      office: filterOfficeLocation,
      assigned_to: filterAssignedTo,
      business_category: filterBusinessCategory
    }
    const params = { keyword: search, length: PageSize, start: currentPage, filter: filter };
    post(GET_LEADS_DATA, params).then(res => {
      if (res) {
        settableData(res?.data)
        setTotalItems(res?.total)
        hideLoader()
      }
    }).catch(err => {
      hideLoader()
      toastr.error(err)
    })
  }
  const SetFilterStage = (e) => {
    let stages = document.getElementsByClassName('leads-stages')
    let selected_stages = []
    for (var i = 0; i < stages.length; ++i) { if (stages[i].checked === true) { selected_stages.push(stages[i].value) } }
    setFilterStages(selected_stages)
  }

  const reloadBoard = () => {
    setBoardReload(!BoardReload)
  }
  
  const modalReminder = (id) => {
    setLeadId(id);
    // Fetch the reminder data for the specific lead
    get(GET_REMINDER, { params: { lead_id: id } })
      .then(res => {
        if (res.status) {
          // Assuming res.data contains the reminder data for the lead
          const reminderData = res.data;
          
          // Toggle the reminder modal and pass the reminder data
          setReminderData(reminderData);  // Assuming `setReminderData` is a state setter for reminders
          toggleReminder(!isShowingReminder);
        }else{
          setReminderData('');
          toggleReminder(!isShowingReminder);
        }
      })
      .catch(err => {
        toastr.error("Failed to fetch reminders", err);
        setReminderData('');
        toggleReminder(!isShowingReminder);
      });
  };  

  const downloadData = () => {
    setCsvLoad(true)
    let filter = {
      stage: filterStages,
      start_date: filterStartDate,
      end_date: filterEndDate,
      source: filterSource,
      brand: filterBrand,
      client_location: filterClientLocation,
      office: filterOfficeLocation,
      assigned_to: filterAssignedTo,
      business_category: filterBusinessCategory
    }
    const params = { length: 100000, start: 1, keyword: search, filter: filter};
    post(GET_LEADS_DATA, params).then(response => {
        if (response.data.length > 0) {
            setHeaders([
              { label: "Lead Name", key: "name"},
              { label: "Campaign Name", key: "campaign_name"},
              { label: "Stage", key: "stage"},
              { label: "Source", key: "source"},
              { label: "Brand", key: "brand"},
              { label: "Business Category", key: "business_category"},
              { label: "Company Name", key: "company_name"},
              { label: "Designation", key: "designation"},
              { label: "Email", key: "email"},
              { label: "Mobile", key: "mobile_full" },
              { label: "Alternate contact number", key: "alternate_mobile_full" },
              { label: "Enquiry Date", key: "enquiry_date"},
              { label: "Date of Event", key: "date_of_event"},
              { label: "Bride & Groom Name", key: "bride_groom_name"},
              { label: "Venue of Event", key: "venue_of_event"},
              { label: "Client's State", key: "state"},
              { label: "Client's City", key: "client_location"},
              { label: "No of People", key: "no_of_people"},
              { label: "No of Functions", key: "no_of_functions"},
              { label: "No of Days", key: "no_of_days"},
              { label: "What Star Hotel", key: "what_star_hotel"},
              { label: "Community", key: "community"},
              { label: "Type of Artist required", key: "type_of_artist_required"},
              { label: "Budget", key: "budget"},
              { label: "What services are required", key: "service_required_name"},
              { label: "Type of Franchise", key: "type_of_franchise"},
              { label: "Job/Business Type", key: "job_business_type"},
              { label: "Assigned to", key: "assigned_to"},
              { label: "Created By", key: "created_by"},
              { label: "Created Date", key: "created_at"}
            ])
            setNewData(response.data);
            if (newData && csvInstance.current && csvInstance.current.link) {
                setTimeout(() => {
                    csvInstance.current.link.click();
                    setNewData([]);
                });
            }
        } else {
            toastr.error(response.message)
        }
        setTimeout(()=>{
          setCsvLoad(false)
        }, 1000)
    }).catch(err => {
        setCsvLoad(false)
        toastr.error(err?.response?.data?.message)
    })
  }

  const downloadCSV = (data,filename) => {
    // Convert the array of objects to CSV format
    const csvRows = [];
    
    // Get headers
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(',')); // join headers by comma
    
    // Loop over the rows
    for (const row of data) {
      const values = headers.map(header => {
        let cellValue = row[header] === null || row[header] === undefined ? '' : row[header]; // replace null or undefined with an empty string
        const escape = ('' + cellValue).replace(/"/g, '\\"');
        return `"${escape}"`; // wrap in double quotes and escape any inner quotes
      });
      csvRows.push(values.join(',')); // join values by comma
    }
  
    // Create a Blob from the CSV string
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
  
    // Create a link element and trigger the download
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const ImportCSV = (e) => {
    e.preventDefault();
    let file = document.getElementById("file").files[0];
    if (file !== undefined) {
      const formData = new FormData();
      formData.append("master_name", "Lead");
      formData.append("master_data", file);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_APIURL}${LEAD_IMPORT}`,
        data: formData,
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      })
        .then(response => {
          if (response.data.status == true) {
            document.getElementById("file").value = "";
            reloadBoard();
            if (response.data.data.msg) {
              toastr.success(response.data.data.msg);
            }
            if(response.data.data.result){
            downloadCSV(response.data.data.result,'lead_rejected_data.csv');
            }
          }else{
            toastr.error(response.data.message);
          }
        });
    } else {
        toastr.error("Please select a valid excel sheet");
    }
  };
  
  const stageColors = {
    1: "lightgray",
    2: "#b7b7ff",
    3: "white",
    4: "#ffd382",
    5: "#6cd96c",
    6: "#ff7f7f",
    7: "#ffff5b",
    8: "pink"
  };
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Leads | PARTY CRUISERS LTD</title>
        </MetaTags>
        <div className="container-fluid">
          <Card className="sticky-main-header">
            <CardBody>
              <div className="d-flex flex-wrap justify-content-between">
                <div className="mt-2 mb-3">
                  <h4 className="m-0">LEADS</h4>
                </div>
                <div className="search-box me-2" style={{ width:"100%", maxWidth: "40%", minWidth: "300px" }}>
                  <div className="position-relative mb-3">
                    <input
                      type="text" onChange={e => { setSearch(e.target.value) }}
                      className="form-control bg-light border-light rounded"
                      placeholder="Search lead by Name, Email, Mobile"
                    />
                    <i className="bx bx-search-alt search-icon"></i>
                  </div>
                </div>  
                <div className="mb-3 d-flex gap-2">
                  <button title="Export" type="button" onClick={syncFacebook} className="btn-rounded btn btn-outline-primary" style={{minWidth:'140px'}}><i className="mdi mdi-facebook"></i> {syncLoad === false && <>Sync Facebook</> }{syncLoad === true && <>Please wait...</> }</button>
                  <button title="Export" type="button" onClick={downloadData} className="btn-rounded btn btn-outline-primary"><i className="mdi mdi-file-export"></i> {csv_load === false && <>Export CSV</> }{csv_load === true && <>Please wait...</> }</button>
                  {headers.length > 0 && <CSVLink data={newData} headers={headers} filename={`Leads.csv`} target="_blank" ref={csvInstance}></CSVLink>}
                </div>              
              </div>
              <div className="d-flex flex-wrap justify-content-between">
                <div className="d-flex justify-content-start mb-3">
                  <div className="text-sm-end me-2">
                    <a onClick={toggleCall} className="btn btn-primary waves-effect waves-light"><i
                          className="bx bx-plus font-size-16 align-middle me-2"></i> Add Call</a>
                  </div>
                  <div className="text-sm-end">
                    <a onClick={toggle} className="btn btn-primary waves-effect waves-light"><i
                          className="bx bx-plus font-size-16 align-middle me-2"></i> Add Meeting</a>
                  </div>
                </div>
                <div className="d-flex flex-wrap ">
                  <div className="me-2 mb-3">
                    <button  title="Export" type="button" onClick={downloadLeadSampleCsv} style={{minWidth: '123px', display: 'flex'}} className="btn btn-primary me-2 mb-2">Sample Import</button>
                  </div>
                  <div className="me-2 mb-3"> 
                    <form className="d-flex flex-wrap"  onSubmit={ImportCSV}>
                      <div className="input-group">
                        <Input
                          type="file"
                          className="form-control"
                          name={"file"}
                          accept={".xlsx"}
                          id="file"
                          aria-describedby="file"
                          aria-label="Upload"
                          style={{ height: '36px',maxWidth: '250px' }}
                        />
                        <Button
                        color="primary"
                        type="submit"
                        id="inputGroupFileAddon04"
                        style={{ height: '36px' }}
                        >
                        Import
                        </Button>
                      </div>
                    </form>
                  </div>
                  <div className="text-sm-end">
                    <a href="/leads/add"
                          className="btn btn-primary waves-effect waves-light"><i
                          className="bx bx-plus font-size-16 align-middle me-2"></i> Add Lead</a>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Row>
            <Col md={4}>
              <div className="sticky-with-banner">
                <h6>FILTER BY</h6>
                <Card>
                  <CardBody>
                    <h6 className="card-title mb-3">Date Range</h6>
                    <span className="d-flex justify-content-between flex-column gap-3 flex-xxl-row"> 
                      <span>
                        <p className="mb-1">Start Date</p>
                        <input  type="date" 
                         onChange={e => {
                          setFilterStartDate(e.target.value);
                        }} className="form-control" style={{width: "100%" }}  />
                      </span>
                      <span>
                        <p className="mb-1">End Date</p>
                        <input  type="date"
                          min={filterStartDate}
                         onChange={e => {
                          setFilterEndDate(e.target.value);
                        }} className="form-control" style={{width: "100%" }}  />
                      </span>
                    </span>
                     <hr />
                    <h6 className="card-title mb-3">BRAND</h6>
                    <Select options={Brand} isMulti onChange={e => { setFilterBrand(e)}} placeholder={'Select Brand'} isClearable classNamePrefix="select2-selection" />
                    <hr />
                    <h6 className="card-title mb-3">BUSINESS CATEGORY</h6>
                    <Select options={BusinessCategory} isMulti onChange={e => { setFilterBusinessCategory(e)}} placeholder={'Select Bussiness Category'} isClearable classNamePrefix="select2-selection" />
                    <hr />
                    <h6 className="card-title mb-3">ASSIGNED TO</h6>
                    <Select options={AssignedTo} onChange={e => { setFilterAssignedTo(e)}} isMulti placeholder={'Select Assigned To'} isClearable classNamePrefix="select2-selection" />
                    <hr />
                    <h6 className="card-title mb-3">SOURCE</h6>
                    <Select options={LeadSource} onChange={e => { setFilterSource(e)}} isMulti placeholder={'Select Source'} isClearable classNamePrefix="select2-selection" />
                    <hr />
                    <h6 className="card-title mb-3">CLIENT LOCATION</h6>
                    <Select options={ClientLocation} onChange={e => { setFilterClientLocation(e)}} isMulti placeholder={'Select Client Location'} isClearable classNamePrefix="select2-selection" />
                    <hr />
                    <h6 className="card-title mb-3">OFFICE</h6>
                    <Select options={ClientLocation} onChange={e => { setFilterOfficeLocation(e)}} isMulti placeholder={'Select Office'} isClearable classNamePrefix="select2-selection" />
                    <hr />
                    <h6 className="card-title mb-3">STAGES</h6>
                    {LeadsStages && LeadsStages.map((val, key) => {
                      return (
                        <div className="form-check mb-2" key={key}>
                          <Input
                            type="checkbox"
                            className="form-check-input leads-stages"
                            value={val.value}  // Assuming val is an object with a value property
                            id={`stage-${val.value}`}  // Assuming val is an object with a value property
                            onChange={e => { SetFilterStage(e) }}
                          />
                          <Label className="form-check-label" htmlFor={`stage-${val.value}`}>
                            {val.label}
                          </Label>
                        </div>
                      );
                    })}
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col md={8}>
              <div className="d-flex justify-content-between">
                <h6>{totalItems} Leads</h6>
                {/*<h6>BULK ACTIONS</h6>*/}
              </div>
              {/*<Card>*/}
              {/*  <CardBody>*/}
              <div className="">
                {loader && 
                  <div className="wrapper">
                    <div className="wrapper-cell">
                      <div className="text">
                        <div className="text-line"> </div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                      </div>
                    </div>
                    <div className="wrapper-cell">
                      <div className="text">
                        <div className="text-line"> </div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                      </div>
                    </div>
                  </div>}
                  {(!loader && tableData.length === 0) && <h4 className="text-center">No data found</h4>}
                  {!loader && tableData.map((value, index) => (
                     <Card key={index} className="candidate-card">
                     <CardBody>
                       <div className="d-flex">
                         <div className="flex-grow-1 overflow-hidden">
                           <h4 className="text-truncate fw-bold font-size-18">
                             <Link to={`/lead/${value?.id}`} className="text-dark">
                                {value?.name}
                             </Link>
                           </h4>
                           <div className="candidate-meta">
                              {value?.campaign_name !== '' && <span>Campaign: <span className="text-dark text-primary">{value?.campaign_name}</span></span>}
                            </div>
                           <div className="candidate-meta">
                             {value?.source !== null && <span>Source: <span className="text-dark text-primary">{value?.source}</span></span>}
                             {value?.assigned_to !== null && <span> | Assigned To: <span className="text-dark text-primary">{value?.assigned_to}</span></span>}
                           </div>
                           <div className="d-flex flex-wrap justify-content-between">
                            <div className="candidate-meta">
                              {value?.brand !== null && <span>Brand: <span className="text-dark text-primary">{value?.brand}</span></span>}
                              {value?.business_category !== null && <span> | Business Category: <span className="text-dark text-primary">{value?.business_category}</span></span>}
                            </div>
                            <div className="candidate-meta">
                                {value?.stage_id !== null && (
                                    <span
                                        className="text-dark text-primary"
                                        style={{
                                            background: stageColors[value?.stage_id] || "transparent", // Fallback to transparent if stage_id not found
                                            paddingInline: "5px",
                                            borderRadius: "5px"
                                        }}
                                    >
                                        Stage: {value?.stage}
                                    </span>
                                )}
                            </div>
                           </div>
                           <div className="d-flex justify-content-between w-100">
                           <ul className="list-inline mb-0">
                              {value?.email !== null && value?.email !== '' && 
                                <li className="list-inline-item me-3" id="dueDate">
                                  <i className="bx bx-envelope me-1" /> {value?.email}
                                </li>
                              }
                              {value?.mobile !== null && value?.mobile !== '' && 
                                <li className="list-inline-item me-3" id="comments">
                                  <i className="bx bx-phone me-1" />{" "} {value?.country_code}&nbsp;{value?.mobile}
                                </li>
                              }
                              {value?.client_location !== null && value?.client_location !== '' && 
                                <li className="list-inline-item me-3" id="comments">
                                  <i className="bx bx-map me-1" />{" "} {value?.client_location}
                                </li>
                              }
                           </ul>
                           </div>
                         </div>
                         <div className="d-flex flex-column justify-content-between" style={{ alignItems: 'end' }}>
                          <div className="candidate-card-tags" style={{ gridTemplateColumns: 'auto auto auto auto auto' }}>
                            <Link to={`/lead/${value?.id}?tabId=3`} className="tags-option" id={`tags-option-1-2`}><i className="bx bx-phone"></i> <UncontrolledTooltip placement="top" target={`tags-option-1-2`}>View Call</UncontrolledTooltip></Link>
                            <Link to={`/lead/${value?.id}?tabId=4`} className="tags-option" id={`tags-option-1-5`}><i className="bx bx-calendar"></i> <UncontrolledTooltip placement="top" target={`tags-option-1-5`}>View Meeting</UncontrolledTooltip></Link>
                            <Link to={`/lead/${value?.id}?tabId=2`} className="tags-option" id={`tags-option-1-1`}><i className="bx bxs-file"></i> <UncontrolledTooltip placement="top" target={`tags-option-1-1`}>View Notes</UncontrolledTooltip></Link>
                            <Link to={`/lead/${value?.id}?tabId=5`} className="tags-option" id={`tags-option-1-3`}><i className="bx bx-paperclip"></i> <UncontrolledTooltip placement="top" target={`tags-option-1-3`}>View Attachments</UncontrolledTooltip></Link>
                            <Link  onClick={() => modalReminder(value?.id)}  data-lead-d={`${value?.id}`} className="tags-option" id={`tags-option-1-4`}><i className="bx bx-alarm"></i> <UncontrolledTooltip placement="top" target={`tags-option-1-4`}>Add Reminder</UncontrolledTooltip></Link>
                          </div>
                          { value?.upcoming &&
                              <div
                                  className=""
                                  style={{
                                      background: '#003070',
                                      color: '#fff',
                                      borderRadius: '50px',
                                      fontSize:'10px',
                                      textAlign: 'center',
                                      position:'relative',
                                      width: 'fit-content',
                                      minWidth: '230px',
                                      padding: '5px 10px'
                                  }}
                              >
                                <span style={{width:"10px",height:'10px', borderRadius:'50%', position:'absolute', top:'0', right:'0', background:'red'}}></span>
                                  {formatUpcomingEvent(value?.upcoming?.title, value?.upcoming?.value)}
                              </div>
                          }
                         </div>
                       </div>
                     </CardBody>
                    </Card>
                  ))}
              </div>
              <Row className="justify-content-center">
                <Col className="col-auto">
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={totalItems}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                  />
                </Col>
              </Row>
              {/*  </CardBody>*/}
              {/*</Card>*/}
            </Col>
          </Row>
          {isShowing === true &&
            <AddMeeting
              isShowing={isShowing}
              reloadBoard={reloadBoard}
              hide={toggle}
            />
          }
          {isShowingCall === true &&
            <AddCall
              isShowing={isShowingCall}
              reloadBoard={reloadBoard}
              hide={toggleCall}
            />
          }  
          {isShowingReminder === true &&
            <AddReminder
              isShowing={isShowingReminder}
              reloadBoard={reloadBoard}
              hide={toggleReminder}
              lead_id={leadId}
              reminderData={reminderData}
            />
          }          
        </div>
      </div>
    </React.Fragment>
  );
};

export default Lead;
